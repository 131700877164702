//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import MobileSidebar from "./sidebar/MobileSidebar";
import DefaultSidebar from "./sidebar/DefaultSidebar";
import $ from "jquery";

export default {
  name: "Sidebar",
  data() {
    return {
      codeValue: '',
      emailValue: '',
      isPersonal: false,
      isMails: false,
      emailError: ''
    }
  },
  components: {DefaultSidebar, MobileSidebar},
  computed: {
    isChecks() {
      return this.isPersonal;
    },
    ...mapState([
      'worker',
      'history',
      'stats',
      'email',
      'code',
      'codeError'
    ]),
    ...mapGetters([
        'isIdAuthMethod'
    ])
  },
  methods: {
    addMail() {
      this.emailError = '';

      if (this.isChecks) {
        let response = this.addEmail({email: this.emailValue, subscribe: this.isMails});

        response.then(res => {
          if (!res.data.result || res.data.errorStr) {
            this.emailError = res.data.errorStr;
          } else {
            $('#modalConfirm').modal('show');
          }
        });
      }
    },
    confirmMail() {
      if (this.codeValue) {
        let response = this.confirmEmail(this.codeValue);
        let self = this;

        response.then(res => {
          if (!res.data.result || res.data.errorStr) {
            self.codeError = res.data.errorStr;
          } else {
            self.getWorker();
            $('#modalAddMail').modal('hide');
            $('#modalConfirm').modal('hide');
          }
        });
      }
    },
    ...mapMutations([
      'setEmailError'
    ]),

    ...mapActions([
      'addEmail',
      'confirmEmail',
      'getWorker',
    ])
  }
}
